var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      staticClass: "pt-8",
      staticStyle: { height: "100%" },
      attrs: { justify: "center" }
    },
    [
      _c(
        "v-col",
        { attrs: { cols: "12", align: "center", "align-self": "start" } },
        [
          _c(
            "v-card",
            { staticClass: "text-center", attrs: { flat: "" } },
            [
              _c(
                "p",
                {
                  staticClass: "px-3",
                  class: _vm.$vuetify.breakpoint.smAndDown
                    ? "text-h4 mb-5"
                    : "text-h2 mb-10"
                },
                [_vm._v(" " + _vm._s(_vm.$t("createTeamPage.title")) + " ")]
              ),
              _c(
                "p",
                {
                  staticClass: "px-3",
                  class: _vm.$vuetify.breakpoint.smAndDown
                    ? "text-h6 mb-5"
                    : "text-h4 mb-10"
                },
                [_vm._v(" " + _vm._s(_vm.$t("createTeamPage.subtitle")) + " ")]
              ),
              _c(
                "v-form",
                {
                  ref: "form",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "11", sm: "10", md: "8", lg: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t(
                                "createTeamPage.questions.teamMemberName.label"
                              ),
                              "prepend-inner-icon": "mdi-account",
                              filled: "",
                              rules: [_vm.rules.nameRequired]
                            },
                            model: {
                              value: _vm.teamMemberName,
                              callback: function($$v) {
                                _vm.teamMemberName = $$v
                              },
                              expression: "teamMemberName"
                            }
                          }),
                          _c("v-select", {
                            attrs: {
                              "item-text": "text",
                              "item-value": "value",
                              label: _vm.$t(
                                "createTeamPage.questions.role.label"
                              ),
                              "prepend-inner-icon": "mdi-tooltip-account",
                              filled: "",
                              items: _vm.roleOptions,
                              rules: [_vm.rules.roleRequired],
                              "hide-details":
                                _vm.role ===
                                _vm.roleOptions[_vm.roleOptions.length - 1]
                                  .value
                            },
                            model: {
                              value: _vm.role,
                              callback: function($$v) {
                                _vm.role = $$v
                              },
                              expression: "role"
                            }
                          }),
                          _c(
                            "v-slide-y-transition",
                            [
                              _vm.role ===
                              _vm.roleOptions[_vm.roleOptions.length - 1].value
                                ? _c("v-text-field", {
                                    staticClass: "mt-4",
                                    attrs: {
                                      outlined: "",
                                      rules: [_vm.rules.roleRequired],
                                      label: _vm.$t(
                                        "createTeamPage.questions.role.customLabel"
                                      ),
                                      "append-outer-icon": "mdi-close"
                                    },
                                    on: {
                                      "click:append-outer": function($event) {
                                        _vm.role = null
                                        _vm.customRole = null
                                      }
                                    },
                                    model: {
                                      value: _vm.customRole,
                                      callback: function($$v) {
                                        _vm.customRole = $$v
                                      },
                                      expression: "customRole"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t(
                                "createTeamPage.questions.companyName.label"
                              ),
                              "prepend-inner-icon": "mdi-domain",
                              filled: "",
                              rules: [_vm.rules.companyNameRequired]
                            },
                            model: {
                              value: _vm.teamName,
                              callback: function($$v) {
                                _vm.teamName = $$v
                              },
                              expression: "teamName"
                            }
                          }),
                          _c("v-select", {
                            attrs: {
                              label: _vm.$t(
                                "createTeamPage.questions.vidaUse.label"
                              ),
                              "prepend-inner-icon": "mdi-heart",
                              filled: "",
                              items: _vm.vidaUses,
                              "hide-details":
                                _vm.vidaUse ===
                                _vm.vidaUses[_vm.vidaUses.length - 1].value,
                              hint: _vm.$t(
                                "createTeamPage.questions.vidaUse.message"
                              ),
                              "persistent-hint": ""
                            },
                            model: {
                              value: _vm.vidaUse,
                              callback: function($$v) {
                                _vm.vidaUse = $$v
                              },
                              expression: "vidaUse"
                            }
                          }),
                          _c(
                            "v-slide-y-transition",
                            [
                              _vm.vidaUse ===
                              _vm.vidaUses[_vm.vidaUses.length - 1].value
                                ? _c("v-text-field", {
                                    staticClass: "mt-4",
                                    attrs: {
                                      outlined: "",
                                      "item-text": "text",
                                      "item-value": "value",
                                      label: _vm.$t(
                                        "createTeamPage.questions.vidaUse.customLabel"
                                      ),
                                      "append-outer-icon": "mdi-close"
                                    },
                                    on: {
                                      "click:append-outer": function($event) {
                                        _vm.vidaUse = null
                                        _vm.customUse = null
                                      }
                                    },
                                    model: {
                                      value: _vm.customUse,
                                      callback: function($$v) {
                                        _vm.customUse = $$v
                                      },
                                      expression: "customUse"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-tooltip",
                            {
                              attrs: {
                                bottom: "",
                                disabled: _vm.validDomain,
                                color: "transparent"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "div",
                                        _vm._g(
                                          _vm._b({}, "div", attrs, false),
                                          on
                                        ),
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              disabled: !_vm.validDomain,
                                              label: _vm.$t(
                                                "createTeamPage.domainName",
                                                { 0: _vm.domainName }
                                              )
                                            },
                                            model: {
                                              value: _vm.saveDomainName,
                                              callback: function($$v) {
                                                _vm.saveDomainName = $$v
                                              },
                                              expression: "saveDomainName"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c("vitrue-tooltip", {
                                attrs: {
                                  message: _vm.$t(
                                    "createTeamPage.invalidDomainTooltip"
                                  )
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-none",
                              attrs: {
                                color: "primary",
                                width: "100%",
                                loading: _vm.creatingTeam
                              },
                              on: {
                                click: function($event) {
                                  return _vm.createTeamForUser()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("buttons.submit")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-col",
        {
          staticClass: "text-center text-body-1",
          attrs: { cols: "10", "align-self": "end" }
        },
        [
          _vm._v(
            _vm._s(
              _vm.$t("authentication.createTeamPage.linkToJoinTeam.text")
            ) + " "
          ),
          _c(
            "span",
            {
              staticClass: "primary--text",
              staticStyle: { cursor: "pointer" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/join-team")
                }
              }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t("authentication.createTeamPage.linkToJoinTeam.link")
                )
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-row justify="center" class="pt-8" style="height: 100%">
    <v-col cols="12" align="center" align-self="start">
      <v-card flat class="text-center">
        <p
          class="px-3"
          :class="
            $vuetify.breakpoint.smAndDown ? 'text-h4 mb-5' : 'text-h2 mb-10'
          "
        >
          {{ $t("createTeamPage.title") }}
        </p>
        <p
          class="px-3"
          :class="
            $vuetify.breakpoint.smAndDown ? 'text-h6 mb-5' : 'text-h4 mb-10'
          "
        >
          {{ $t("createTeamPage.subtitle") }}
        </p>
        <v-form ref="form" v-model="valid" @submit.prevent>
          <v-row justify="center">
            <v-col cols="11" sm="10" md="8" lg="4">
              <v-text-field
                v-model="teamMemberName"
                :label="$t('createTeamPage.questions.teamMemberName.label')"
                prepend-inner-icon="mdi-account"
                filled
                :rules="[rules.nameRequired]"
              ></v-text-field>
              <v-select
                v-model="role"
                item-text="text"
                item-value="value"
                :label="$t('createTeamPage.questions.role.label')"
                prepend-inner-icon="mdi-tooltip-account"
                filled
                :items="roleOptions"
                :rules="[rules.roleRequired]"
                :hide-details="
                  role === roleOptions[roleOptions.length - 1].value
                "
              >
              </v-select>
              <v-slide-y-transition>
                <v-text-field
                  v-if="role === roleOptions[roleOptions.length - 1].value"
                  v-model="customRole"
                  outlined
                  :rules="[rules.roleRequired]"
                  :label="$t('createTeamPage.questions.role.customLabel')"
                  class="mt-4"
                  append-outer-icon="mdi-close"
                  @click:append-outer="
                    role = null;
                    customRole = null;
                  "
                ></v-text-field>
              </v-slide-y-transition>
              <v-text-field
                v-model="teamName"
                :label="$t('createTeamPage.questions.companyName.label')"
                prepend-inner-icon="mdi-domain"
                filled
                :rules="[rules.companyNameRequired]"
              ></v-text-field>
              <v-select
                v-model="vidaUse"
                :label="$t('createTeamPage.questions.vidaUse.label')"
                prepend-inner-icon="mdi-heart"
                filled
                :items="vidaUses"
                :hide-details="vidaUse === vidaUses[vidaUses.length - 1].value"
                :hint="$t('createTeamPage.questions.vidaUse.message')"
                persistent-hint
              >
              </v-select>

              <v-slide-y-transition>
                <v-text-field
                  v-if="vidaUse === vidaUses[vidaUses.length - 1].value"
                  v-model="customUse"
                  outlined
                  item-text="text"
                  item-value="value"
                  :label="$t('createTeamPage.questions.vidaUse.customLabel')"
                  class="mt-4"
                  append-outer-icon="mdi-close"
                  @click:append-outer="
                    vidaUse = null;
                    customUse = null;
                  "
                ></v-text-field>
              </v-slide-y-transition>
              <v-tooltip bottom :disabled="validDomain" color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <v-checkbox
                      v-model="saveDomainName"
                      :disabled="!validDomain"
                      :label="
                        $t('createTeamPage.domainName', { 0: domainName })
                      "
                    ></v-checkbox>
                  </div>
                </template>
                <vitrue-tooltip
                  :message="$t('createTeamPage.invalidDomainTooltip')"
                />
              </v-tooltip>
              <v-btn
                class="text-none"
                color="primary"
                width="100%"
                @click="createTeamForUser()"
                :loading="creatingTeam"
              >
                {{ $t("buttons.submit") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-col>
    <v-col cols="10" align-self="end" class="text-center text-body-1"
      >{{ $t("authentication.createTeamPage.linkToJoinTeam.text") }}
      <span
        class="primary--text"
        style="cursor: pointer"
        @click="$router.push('/join-team')"
        >{{ $t("authentication.createTeamPage.linkToJoinTeam.link") }}</span
      >
    </v-col>
  </v-row>
</template>

<script>
import {
  createTeam,
  checkHasSubscriptionAndSetCustomerId,
  isSubdomainValid,
  userTeamMemberInformation
} from "../../customApi";
import { mapMutations, mapGetters } from "vuex";
import VitrueTooltip from "../common/VitrueTooltip.vue";
import Rules from "@/utils/stringValidation";
import TeamService from "@/services/teamService.js";
import DomainService from "@/services/domain-service.js";

export default {
  components: {
    VitrueTooltip
  },
  data() {
    return {
      teamName: "",
      teamMemberName: "",
      valid: false,
      creatingTeam: false,
      saveDomainName: false,
      validDomain: false,
      role: null,
      vidaUse: null,
      customRole: null,
      customUse: null,
      rules: Rules,
      roleOptions: [
        {
          value: "businessOwner",
          text: this.$t("createTeamPage.questions.role.options.businessOwner")
        },
        {
          value: "headOfHR",
          text: this.$t("createTeamPage.questions.role.options.headOfHR")
        },
        {
          value: "HR",
          text: this.$t("createTeamPage.questions.role.options.HR")
        },
        {
          value: "healthAndSafety",
          text: this.$t("createTeamPage.questions.role.options.healthAndSafety")
        },
        {
          value: "officeManager",
          text: this.$t("createTeamPage.questions.role.options.officeManager")
        },
        {
          value: "teamLeader",
          text: this.$t("createTeamPage.questions.role.options.teamLeader")
        },
        {
          value: "director",
          text: this.$t("createTeamPage.questions.role.options.director")
        },
        {
          value: "other",
          text: this.$t("createTeamPage.questions.role.options.other")
        }
      ],
      vidaUses: [
        {
          value: "compliance",
          text: this.$t("createTeamPage.questions.vidaUse.uses.compliance")
        },
        {
          value: "improvingTeamWellbeing",
          text: this.$t(
            "createTeamPage.questions.vidaUse.uses.improvingTeamWellbeing"
          )
        },
        {
          value: "reducingTeamPain",
          text: this.$t(
            "createTeamPage.questions.vidaUse.uses.reducingTeamPain"
          )
        },
        {
          value: "checkingEquipmentNeeded",
          text: this.$t(
            "createTeamPage.questions.vidaUse.uses.checkingEquipmentNeeded"
          )
        },
        {
          value: "checkSetupForProductivity",
          text: this.$t(
            "createTeamPage.questions.vidaUse.uses.checkSetupForProductivity"
          )
        },
        {
          value: "reducePersonalPain",
          text: this.$t(
            "createTeamPage.questions.vidaUse.uses.reducePersonalPain"
          )
        },
        {
          value: "other",
          text: this.$t("createTeamPage.questions.vidaUse.uses.other")
        }
      ]
    };
  },
  async mounted() {
    if (!this.teamMemberId) {
      let teamMberInfo = await TeamService.getTeamMemberInfo();
      this.setTeamMemberId(teamMberInfo.teamMemberId);
    }

    if (this.signedIn && this.userIsInTeam) {
      this.$router.push("/", () => {});
    }

    this.validDomain = await isSubdomainValid(this.domainName);
    this.saveDomainName = this.validDomain;
  },
  methods: {
    ...mapMutations(["setUserData", "setTeamMemberId"]),
    async createTeamForUser() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }

      let role = this.customRole ?? this.role;

      try {
        this.creatingTeam = true;
        let team = {
          name: this.teamName,
          vidaUse: this.customUse ?? this.vidaUse,
          creatingTeamMember: { name: this.teamMemberName, companyRole: role },
          domainName: this.saveDomainName ? this.domainName : null
        };
        await createTeam(team);
        await checkHasSubscriptionAndSetCustomerId();
        let teamMemberInfo = await userTeamMemberInformation();
        this.setUserData(teamMemberInfo);
        this.$router.push("/dashboard", () => {});
      } catch (err) {
      } finally {
        this.creatingTeam = false;
      }
    }
  },
  computed: {
    ...mapGetters(["userEmail", "signedIn", "userIsInTeam", "teamMemberId"]),
    domainName() {
      return DomainService.getDomainFromEmail(this.userEmail);
    }
  }
};
</script>
